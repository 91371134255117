import {
  useOrganizationFeaturesQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationFeaturesMutation,
  useUpdateOrganizationSettingsMutation,
} from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";
import { Checkbox, Chip, FormControl, FormLabel, Stack } from "@mui/joy";
import { Fragment, memo, useCallback } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";

interface OrganizationFeaturesEditorProps {
  organizationId: string;
}

const OrganizationFeaturesEditor: React.FC<OrganizationFeaturesEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  const {
    data: {
      organization: {
        hasAdvertiserFeatures = false,
        hasAnalyticsFeatures = false,
        hasPublisherFeatures = false,
        hasDataPartnerFeatures = false,
        canViewDataPartners = false,
        canViewMeasurements = false,
        showMigrationPrompt = false,
        useLegacyDataLabs = false,
        allowExcludingSeedAudience = false,
        enableExtendedLookalikeQualityStatistics = false,
        enableAudienceBuilder = false,
      } = {},
    } = {},
  } = useOrganizationFeaturesQuery({
    variables: { organizationId },
  });

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationFeaturesMutation] =
    useUpdateOrganizationFeaturesMutation();
  const [updateOrganizationSettingsMutation] =
    useUpdateOrganizationSettingsMutation({
      onError: (error) => {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
    });

  const updateOrganizationFeatures = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      try {
        await updateOrganizationFeaturesMutation({
          variables: {
            input: {
              id: organizationId,
              [target.name]: target.checked,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      }
    },
    [organizationId, enqueueSnackbar, updateOrganizationFeaturesMutation]
  );

  // Updating these checkbox has to be in a separate mutation due to Access Policies on a backend
  const handleUpdateOrganizationSettings = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      updateOrganizationSettingsMutation({
        variables: {
          input: {
            [target.name]: target.checked,
            organizationId,
          },
        },
      });
    },
    [organizationId, updateOrganizationSettingsMutation]
  );

  const isEditorDisabled =
    !organizationLicense ||
    [OrganizationState.Archived].includes(organizationLicense);

  const isPublisherCheckboxEnabled =
    organizationLicense === OrganizationState.Passive && isSuperAdmin;

  return (
    <FormControl>
      <FormLabel>Features</FormLabel>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ "& > *": { flex: "0 1 calc(50% - 8px / 2)" } }}
      >
        {isSuperAdmin ? (
          <Fragment>
            <FormControl>
              <Checkbox
                checked={!!hasAdvertiserFeatures}
                disabled={isEditorDisabled}
                label="Advertiser features"
                name="hasAdvertiserFeatures"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasAnalyticsFeatures}
                disabled={isEditorDisabled}
                label="Analytics features"
                name="hasAnalyticsFeatures"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasPublisherFeatures}
                disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
                label="Publisher features"
                name="hasPublisherFeatures"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasDataPartnerFeatures}
                disabled={isEditorDisabled}
                label="Data partner features"
                name="hasDataPartnerFeatures"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!canViewDataPartners}
                disabled={isEditorDisabled}
                label="Can view data partners"
                name="canViewDataPartners"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!canViewMeasurements}
                disabled={isEditorDisabled}
                label="Can view measurements"
                name="canViewMeasurements"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!allowExcludingSeedAudience}
                disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
                label="Allow excluding seed audience"
                name="allowExcludingSeedAudience"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!showMigrationPrompt}
                disabled={isEditorDisabled}
                label="Show migration prompt"
                name="showMigrationPrompt"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!enableAudienceBuilder}
                disabled={isEditorDisabled}
                label="Enable audience builder DCR"
                name="enableAudienceBuilder"
                onChange={updateOrganizationFeatures}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!useLegacyDataLabs}
                disabled={isEditorDisabled}
                label="Create 4.0 datalabs"
                name="useLegacyDataLabs"
                onChange={handleUpdateOrganizationSettings}
              />
            </FormControl>
          </Fragment>
        ) : (
          <Fragment>
            {hasAdvertiserFeatures && <Chip>Advertiser</Chip>}
            {hasAnalyticsFeatures && <Chip>Analytics</Chip>}
            {hasPublisherFeatures && <Chip>Publisher</Chip>}
            {hasDataPartnerFeatures && <Chip>Data partner</Chip>}
          </Fragment>
        )}
        <Checkbox
          checked={!!enableExtendedLookalikeQualityStatistics}
          disabled={isEditorDisabled}
          label="Enable extended lookalike quality statistics"
          name="enableExtendedLookalikeQualityStatistics"
          onChange={handleUpdateOrganizationSettings}
        />
      </Stack>
    </FormControl>
  );
};

OrganizationFeaturesEditor.displayName = "OrganizationFeaturesEditor";

export default memo(OrganizationFeaturesEditor);
