import {
  dataSourceTypePresentation,
  DqLoader,
  DqTable,
} from "@decentriq/components";
import { useDatasetsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type DatasetsQuery,
  type DataSourceType,
} from "@decentriq/graphql/dist/types";
import {
  faCircleCheck as falCircleCheck,
  faFile as falFile,
  faKey as falKey,
  faTable as falTable,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Tooltip, Typography } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import React, { memo, useCallback, useMemo, useState } from "react";
import { KeychainItemKind } from "services/keychain";
import { EmptyData, TimeAgoFormatted } from "components";
import {
  DatasetsViewSelect,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets/components";
import { DatasetsView } from "features/datasets/models";
import { formatSize } from "features/datasets/utils";
import {
  defaultDatasetKeychainItemKinds,
  useKeychainItems,
} from "hooks/keychain/useKeychainItems";
import { type DataType } from "models";
import { ellipsisify } from "utils";
import DatasetDrawer from "../DatasetDrawer/DatasetDrawer";
import DatasetActionsMenu from "./components/DatasetActionsMenu/DatasetActionsMenu";

type DatasetColumnDef = Omit<Dataset, "__typename">;
type Dataset = DatasetsQuery["datasets"]["nodes"][number] & {
  type: DataType;
  hasKeychainItem: boolean;
};
interface DatasetsListProps {
  view?: DatasetsView;
  customDatasetActions?: (manifestHash: string) => React.ReactNode;
}

const datasetTypeDisplayMap: Record<
  DataType,
  { icon: IconDefinition; label: string }
> = {
  table: {
    icon: falTable,
    label: "Table",
  },
  unstructured: {
    icon: falFile,
    label: "File",
  },
};

const DatasetsList = memo<DatasetsListProps>(
  ({ view, customDatasetActions }) => {
    const [datasetsView, setDatasetsView] = useState(
      view ?? DatasetsView.KeychainOnly
    );
    const canSelectView = !view;
    const { data: datasetsData, loading: isDatasetsDataLoading } =
      useDatasetsQuery({
        fetchPolicy: "network-only",
      });
    const {
      items: keychainItems,
      hasDatasetMetadataItem,
      loading: isKeychainDataLoading,
    } = useKeychainItems({
      kinds: defaultDatasetKeychainItemKinds,
    });
    const hasKeychainItem = useCallback(
      (manifestHash: string): boolean =>
        keychainItems.some(
          ({ id, kind }) =>
            manifestHash === id && kind === KeychainItemKind.Dataset
        ),
      [keychainItems]
    );
    const loading = isDatasetsDataLoading || isKeychainDataLoading;
    const { datasets, totalCount } = useMemo(() => {
      if (!datasetsData?.datasets?.nodes?.length) {
        return {
          datasets: [],
          totalCount: 0,
        };
      }
      const datasets: Dataset[] = datasetsData?.datasets?.nodes
        .slice()
        .map((dataset) => ({
          ...dataset,
          hasKeychainItem: hasKeychainItem(dataset.manifestHash),
          type: (hasDatasetMetadataItem(dataset.manifestHash)
            ? "table"
            : "unstructured") as DataType,
        }))
        .filter(
          ({ hasKeychainItem }) =>
            datasetsView === DatasetsView.All ||
            (datasetsView === DatasetsView.KeychainOnly && hasKeychainItem)
        );
      return {
        datasets,
        totalCount: datasets.length,
      };
    }, [
      datasetsData?.datasets?.nodes,
      datasetsView,
      hasKeychainItem,
      hasDatasetMetadataItem,
    ]);
    const [selectedManifestHash, setSelectedManifestHash] = useState<
      string | null
    >(null);
    const selectedDataset = useMemo(
      () =>
        datasets.find(
          ({ manifestHash }) => manifestHash === selectedManifestHash
        ) ?? null,
      [datasets, selectedManifestHash]
    );
    const datasetsColumnDef: MRT_ColumnDef<DatasetColumnDef>[] = useMemo(
      () => [
        {
          Cell: ({ row, cell }) => {
            const isTabular = row.original.type === "table";
            const name = ellipsisify(cell.getValue<string>(), 35);
            return (
              <Typography
                component="div"
                endDecorator={
                  row.original.hasKeychainItem ? (
                    <Tooltip title="This dataset encryption key is stored in the Keychain.">
                      <FontAwesomeIcon
                        fixedWidth={true}
                        fontSize="1rem"
                        icon={falKey}
                      />
                    </Tooltip>
                  ) : undefined
                }
                fontWeight="500"
                level="inherit"
                noWrap={true}
                startDecorator={
                  <FontAwesomeIcon
                    fixedWidth={true}
                    fontSize="1rem"
                    icon={isTabular ? falTable : falFile}
                  />
                }
                textColor="inherit"
              >
                <Typography component="span" noWrap={true}>
                  {name}
                </Typography>
              </Typography>
            );
          },
          accessorKey: "name",
          header: "Name",
          id: "name",
          size: 200,
        },
        {
          Cell: ({ cell }) => {
            const datasetType = cell.getValue<DataType>();
            const { icon, label } = datasetTypeDisplayMap[datasetType];
            return (
              <Stack alignItems="center" direction="row" gap={1}>
                <FontAwesomeIcon fixedWidth={true} icon={icon} />
                <span>{label}</span>
              </Stack>
            );
          },
          accessorKey: "type",
          header: "Type",
        },
        {
          Cell: ({ cell }) => {
            const createdAt = cell.getValue<string>();
            return createdAt ? (
              <TimeAgoFormatted
                date={createdAt}
                style={{ whiteSpace: "nowrap" }}
              />
            ) : (
              "—"
            );
          },
          accessorKey: "createdAt",
          header: "Upload date",
          id: "createdAt",
          size: 100,
        },
        {
          Cell: ({ cell }) => {
            const sourceType = cell.getValue<DataSourceType>();
            return (
              <Stack alignItems="center" direction="row" gap={1}>
                <ExternalConnectionsIcon
                  connectionType={sourceType}
                  size={ExternalConnectionsIconSize.xs}
                />
                <Typography level="body-sm">
                  {dataSourceTypePresentation.get(sourceType)}
                </Typography>
              </Stack>
            );
          },
          accessorKey: "sourceType",
          header: "Imported from",
          id: "sourceType",
          size: 100,
        },
        {
          Cell: ({ cell }) => formatSize(cell.getValue<number>()),
          accessorKey: "size",
          header: "File size",
          id: "size",
          size: 100,
        },
      ],
      []
    );
    if (loading) {
      return (
        <Stack alignItems="center" height="100%" justifyContent="center">
          <DqLoader size="sm" />
        </Stack>
      );
    }
    if (!loading && !totalCount) {
      return (
        <EmptyData
          // This is workaround to keep same height for empty data between two dataset tabs
          description="&nbsp;"
          title="No datasets have been provisioned to any data clean rooms so far"
        />
      );
    }
    return (
      <Box
        sx={{
          alignItems: "stretch",
          backgroundColor: "common.white",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "stretch",
          overflow: "hidden",
        }}
      >
        <DqTable
          columns={datasetsColumnDef}
          data={datasets}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              enableResizing: false,
              grow: false,
              header: customDatasetActions ? undefined : "Actions",
              muiTableBodyCellProps: {
                align: "left",
                onClick: (event) => event.stopPropagation(),
                sx: {
                  minWidth:
                    "max(calc(var(--col-mrt_row_actions-size)* 1px), 36px)",
                },
              },
              muiTableHeadCellProps: {
                align: "left",
                sx: {
                  fontWeight: "semiBold",
                  minWidth:
                    "max(calc(var(--header-mrt_row_actions-size)* 1px), 36px)",
                },
              },
              size: 160,
            },
          }}
          emptyStateIcon={falCircleCheck}
          enableRowActions={true}
          enableSorting={true}
          enableTopToolbar={canSelectView}
          getRowId={(row) => row.manifestHash}
          initialState={{
            sorting: [{ desc: true, id: "createdAt" }],
          }}
          localization={{
            noRecordsToDisplay: "No datasets found",
          }}
          muiTableBodyRowProps={({
            row: {
              original: { manifestHash },
            },
          }) => {
            return {
              onClick: () => setSelectedManifestHash(manifestHash),
              sx: {
                cursor: "pointer",
              },
            };
          }}
          muiTablePaperProps={{
            sx: {
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
              width: "100%",
            },
          }}
          muiTopToolbarProps={{
            sx: {
              borderBottom: "1px solid",
              borderColor: "divider",
              flex: "0 0 auto",
              minHeight: "auto",
            },
          }}
          renderRowActions={({ row }) =>
            customDatasetActions ? (
              customDatasetActions(row.original.manifestHash)
            ) : (
              <DatasetActionsMenu manifestHash={row.original.manifestHash} />
            )
          }
          renderTopToolbarCustomActions={
            canSelectView
              ? () => (
                  <DatasetsViewSelect
                    selectedDatasetsView={datasetsView}
                    setDatasetsView={setDatasetsView}
                  />
                )
              : undefined
          }
        />
        <DatasetDrawer
          manifestHash={selectedManifestHash}
          onClose={() => setSelectedManifestHash(null)}
          open={Boolean(selectedDataset)}
        />
      </Box>
    );
  }
);

DatasetsList.displayName = "DatasetsList";

export default DatasetsList;
